import React from 'react'
import { Navigate } from 'react-router-dom'
function Protected({ path, children }) {
    const loggedInUser = JSON.parse(localStorage.getItem('user'))
    if(loggedInUser?.roles === 'Manager')
    {
        return children
    }
    else if(loggedInUser.roles==='OEMVendor'){
        if ( path == "UploadDevice" || path == "ListDevice" || path == "OEMVendorDetails" || path == "ListVendor" || path == "AddManager" ||  path == "ViewDevice" || path == "ViewVendor" || path =="Statistics") {
            return <Navigate to="/Authentication/Error404" replace />
          }
    }
  return children
}
export default Protected