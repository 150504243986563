import React, { Fragment, useEffect} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import Auth from "./Authentication/auth";
import Protected from "./components/Protected"

// Dashboard
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const App = React.lazy(() => import("./components/app"));

//FormPage
const UploadDevice = React.lazy(() =>

  import("./components/FormPage/UploadDevice")
);

const OEMVendorDetails = React.lazy(() =>
  import("./components/FormPage/OEMVendorDetails")
);

const ListDevice = React.lazy(() => import("./components/FormPage/ListDevice"));
const ListVendor = React.lazy(() => import("./components/FormPage/ListVendor"));
const EndUserRegistration = React.lazy(() =>
  import("./components/FormPage/EndUserRegistration")
);
const ListEndUserRegistration = React.lazy(() =>
  import("./components/FormPage/ListEndUserRegistration")
);
const ViewDevice = React.lazy(() => import("./components/FormPage/ViewDevice"));
const ViewVendor = React.lazy(() => import("./components/FormPage/ViewVendor"));
const ViewEndUserRegistration = React.lazy(() =>
  import("./components/FormPage/ViewEndUserRegistration")
);
const VendorLinkDevice = React.lazy(() =>
  import("./components/FormPage/VendorLinkDevice")
);
const ListLinkedDevice = React.lazy(() =>
  import("./components/FormPage/ListLinkedDevice")
);
const AddManager = React.lazy(() =>
  import("./components/FormPage/AddManager")
);
const ListManager = React.lazy(() =>
  import("./components/FormPage/ListManager")
);
const ContactUs = React.lazy(() =>
  import("./components/FormPage/ContactUs")
);
const ViewEndUserGeoFence = React.lazy(() =>
  import("./components/FormPage/ViewEndUserGeoFence")
);
const CreateGeoFence = React.lazy(() =>
  import("./components/FormPage/CreateGeoFence")
);
const DeviceMapping = React.lazy(() =>
  import("./components/FormPage/DeviceMapping")
);

const ViewAllUsersGeoFence = React.lazy(() =>
  import("./components/FormPage/ViewAllUsersGeoFence")
);
const Statistics = React.lazy(() =>
  import("./components/Dashboard/Statistics")
);

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const ForgotPassword = React.lazy(() => import("./Authentication/ForgotPassword"));
const ConformationPassword = React.lazy(() => import("./Authentication/ConformationPassword"));

// const  MyComponent  =  React.lazy(() => import("./Authentication/test"));
const Error404 = React.lazy(() => import("./Authentication/Error404"))

const Root = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  //admin page
  const adminRoutes = [
    {
      url: `${process.env.PUBLIC_URL}/dashboard`,
      component: Dashboard,
    },
    {
      url: `${process.env.PUBLIC_URL}/Statistics`,
      component: Statistics,
    },
     {
           url: `${process.env.PUBLIC_URL}/FormPage/UploadDevice`,
            component: UploadDevice,
          },
          {
            url: `${process.env.PUBLIC_URL}/ListDevice`,
            component: ListDevice,
          },
          {
            url: `${process.env.PUBLIC_URL}/FormPage/OEMVendorDetails`,
            component: OEMVendorDetails,
          },
          {
            url: `${process.env.PUBLIC_URL}/FormPage/ViewEndUserGeoFence`,
            component: ViewEndUserGeoFence,
          },
          {
            url: `${process.env.PUBLIC_URL}/FormPage/CreateGeoFence`,
            component: CreateGeoFence,
          },
          
          {
            url: `${process.env.PUBLIC_URL}/ListVendor`,
            component: ListVendor,
          },
          {
            url: `${process.env.PUBLIC_URL}/EndUserRegistration`,
            component: EndUserRegistration,
          },
          {
            url: `${process.env.PUBLIC_URL}/ListEndUserRegistration`,
            component: ListEndUserRegistration,
          },
          {
            url: `${process.env.PUBLIC_URL}/VendorLinkDevice`,
            component: VendorLinkDevice,
          },  
          {
            url: `${process.env.PUBLIC_URL}/ViewAllUsersGeoFence`,
            component: ViewAllUsersGeoFence,
          },  
  ];

  //Vendor page
  const vendorRoutes = [
    {
      url: `${process.env.PUBLIC_URL}/dashboard`,
      component: Dashboard,
    },
          {
            url: `${process.env.PUBLIC_URL}/ListEndUserRegistration`,
            component: ListEndUserRegistration,
          },
  ];

  //const routes = loggedInUser?.roles === 'Manager'  ? adminRoutes: loggedInUser?.roles === 'OEMVendor' ? vendorRoutes : vendorRoutes

  // if(loggedInUser?.roles === 'Manager' )
  // {
  //   alert("manager")

  useEffect(()=>{
    // alert(localStorage.getItem('theme')=="dark-theme")
  if(localStorage.getItem('theme')=="dark-theme")
  {
    document.querySelector("body").classList.toggle("dark-theme");
  }
  },[])
  
    return (
      <Fragment>
        <BrowserRouter>
       {/* <MyComponent></MyComponent> */}
          <React.Suspense fallback={<Loader />}>
            <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
              <Route index element={<AuthLogin />} />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/login`}
                element={<AuthLogin />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/ForgotPassword`}
                element={<ForgotPassword />}
              />
               <Route
                path={`${process.env.PUBLIC_URL}/authentication/page-resetPassword`}
                element={<ConformationPassword />}
              />
               <Route
                path={`${process.env.PUBLIC_URL}/authentication/Error404`}
                element={<Error404 />}
              />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/`}
                element={<App />}>
                <Route>
                
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    element={<Dashboard/>}
                  />
    
                </Route>
                 {/* FormPage */}
                 <Route>
                  <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/OEMVendorDetails`}
                    // element={<OEMVendorDetails />}
                    element={
                    <Protected path={'OEMVendorDetails'}>
                    <OEMVendorDetails />
                    </Protected>
                    }
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/UploadDevice`}
                    // element={<UploadDevice />}
                    element={
                      <Protected path={'UploadDevice'}>
                      <UploadDevice />
                      </Protected>
                      }
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ListDevice`}
                    //element={<ListDevice />}
                    element={
                      <Protected path={'ListDevice'}>
                      <ListDevice />
                      </Protected>
                      }
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ListVendor/`}
                   // element={<ListVendor />}
                   
                   element={
                    <Protected path={'ListVendor/'}>
                    <ListVendor    />
                    </Protected>
                    }
                    
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/EndUserRegistration`}
                    element={<EndUserRegistration />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ListEndUserRegistration`}
                    element={<ListEndUserRegistration />} 
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ViewDevice`}
                    // element={<ViewDevice />} 
                    element={
                      // <Protected path={'ViewDevice'}>
                      <ViewDevice />
                      // </Protected>
                      }
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ViewVendor`}
                   // element={<ViewVendor />} 
                   element={
                    <Protected path={'ViewVendor'}>
                    <ViewVendor />
                    </Protected>
                    }
                  />
                    <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ViewEndUserRegistration`}
                    //element={<ViewEndUserRegistration />} 
                    element={
                      // <Protected path={'ViewEndUserRegistration'}>
                      <ViewEndUserRegistration />
                      // </Protected>
                      }
                  />
                     <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/VendorLinkDevice`}
                    //element={<VendorLinkDevice />} 
                    element={
                      <Protected path={'VendorLinkDevice'}>
                      <VendorLinkDevice />
                      </Protected>
                      }
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ListLinkedDevice`}
                    element={<ListLinkedDevice />} 
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/AddManager`}
                   // element={<AddManager />} 
                   element={
                    <Protected path={'AddManager'}>
                    <AddManager />
                    </Protected>
                    }
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ListManager`}
                   element={
                    <Protected path={'ListManager'}>
                    <ListManager />
                    </Protected>
                    }
                  />
                     <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/DeviceMapping`}
                   element={
                    <Protected path={'DeviceMapping'}>
                    <DeviceMapping />
                    </Protected>
                    }
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ContactUs`}
                   element={
                    <Protected path={'ContactUs'}>
                    <ContactUs />
                    </Protected>
                    }
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ViewEndUserGeoFence`}
                   element={
                    <Protected path={'ViewEndUserGeoFence'}>
                    <ViewEndUserGeoFence />
                    </Protected>
                    }
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/Statistics`}
                   element={
                    <Protected path={'Statistics'}>
                    <Statistics />
                    </Protected>
                    }
                  />
               
                   <Route
                    path={`${process.env.PUBLIC_URL}/FormPage/ViewAllUsersGeoFence`}
                   element={
                    <Protected path={'ViewAllUsersGeoFence'}>
                    <ViewAllUsersGeoFence />
                    </Protected>
                    }
                  />


            
                  </Route>
              </Route>
            </Routes>
          </React.Suspense>
        </BrowserRouter>
            </Fragment>
    );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);